.user-profile {
	display: flex;
	padding-bottom: 2rem;
	align-content: space-evenly;
	justify-content: space-around;
	background-color: #ffffff;
	border: 1px solid #e9eaec;
	border-radius: 0px;
}

.outlet-stats {
	display: flex;
	justify-content: space-between;
	max-width: 80%;
}

.outlet-list {
	width: 38%;
	margin-top: 3rem;
}

.user-info {
	margin-top: 4.5rem;
	width: 33%;
}

.num {
	color: #16d3d3;
}

.asterisk::before {
	content: '*';
	color: red;
	margin-right: 5px;
}

.margin-p {
	margin: 1.5rem 0 !important;
}

.ant-modal-body.ant-modal-footer {
	background: linear-gradient(120deg, #12cadf 0%, #1de4bd 100%);
}

.modal-password {
	background: linear-gradient(120deg, #12cadf 0%, #1de4bd 100%);
}

#machine-table {
	margin-top: 1rem;
}

.editable-cell {
	position: relative;
}

.editable-cell-value-wrap {
	padding: 4px 11px;
	border: 1px solid #d9d9d9;
	border-radius: 2px;
	min-height: 30px;
	height: auto;
	cursor: pointer;
	width: 100%;
	margin-bottom: 20px;
	white-space: nowrap;
}

/* 
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > thead > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tbody > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tfoot > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tfoot > tr > td {
	border-right: 1px solid #f0f0f0;
	padding-bottom: 30px;
} */

/* .editable-row:hover .editable-cell-value-wrap {
	padding: 4px 11px;
	border: 1px solid #d9d9d9;
	border-radius: 2px;
} */

.editable-row {
	padding-bottom: 0px;
}
.user-nav-link {
	border: 1px solid #e1e1e1;
	padding: 3px 10px;
	border-radius: 5px;
	color: #767676;
}

.user-setting-active {
	color: rgb(0, 81, 255);
}

.checkbox-group {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-bottom: 20px;
	width: 200px;
}

@media only screen and (max-width: 728px) {
	.user-profile {
		flex-direction: column;
		height: 100%;
	}

	.outlet-list {
		width: 100%;
	}

	.avatar {
		margin: 0 auto;
	}

	.user-info {
		margin: 0 auto;
		width: 60%;
	}

	.info {
		display: block;
		text-align: center !important;
	}

	.btn-container {
		display: flex;
		justify-content: center;
	}
}

@media only screen and (max-width: 463px) {
	.user-info {
		width: 70%;
	}
}
